import React, { useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CalculateCartTotal} from '../../helpers'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export default function ShopCart({data,itemqty,editproductquantity,removeproduct}) {
  const [totalToPay, settotalToPay] = useState(0);

  function editQty(id, qty){
    editproductquantity(id, qty)
    settotalToPay(CalculateCartTotal(data,itemqty))
  }
  
  return (
    <>
   <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="cart-table">
        <TableHead>
          <TableRow>
            <TableCell>Produit</TableCell>
            <TableCell align="center">Prix</TableCell>
            <TableCell align="center">Quantité</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,index) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">
                    {row.offers == null ? (row.price +' DA'):(row.offers +' DA')}
              </TableCell>
              <TableCell align="center">
                  <TextField
                    required
                    id="outlined-required"
                    label="Quantité"
                    defaultValue={itemqty[index].qty}
                    type="number"
                    size="small"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={event => {
                      editQty(row.id,event.target.value)
                      
                    }}
                  />
              </TableCell>
            <TableCell align="center">
              <IconButton onClick={()=>{ 
                removeproduct(row.id)
              }} color="error" size="small" aria-label="Close">
                <CloseIcon />
              </IconButton>
            </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <div className="d-flex justify-content-end mt-4">
      <p className='producttitle'>Total : {totalToPay == 0 ? CalculateCartTotal(data,itemqty) : totalToPay} DA</p>
    </div>

    </>
  );
}