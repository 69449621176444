import React, { useState, useEffect, useRef, useContext } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useTranslation } from "react-i18next";

export default function Confirmation({
  openconfimation,
  msg,
  handleConfirmation,
  method
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={openconfimation}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={true}
      sx={{ zIndex:99999}}
    >
      <DialogTitle>{"Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="annuler_btn"
          onClick={() => {
            handleConfirmation(false);
          }}
        >
          Annuler
        </Button>
        <Button
          className="continue_btn"
          onClick={() => {
            handleConfirmation(false);
            method();
          }}
        >
          Continuer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
