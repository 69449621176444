import React, { useEffect } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { nFormatter } from "../../helpers";

export default function Stats({ balance,  unpaid }) {
  const { t } = useTranslation();
  return (
    <div className="container stats-wrapper ">
    
      <div className="cards row justify-content-center">
        <div className="col-md-3 mb-3 ">
          <div className="card   statistics element_shadow">
            <div className="card-body text-center">
              <h3 className="stat-item-title fw-bold"> {nFormatter(balance)}</h3>
              <label className="stat-item-subtitle fw-bold">Solde</label>
            </div>
          </div>
        </div>
       
        <div className="col-md-3 mb-3">
          <div className="card   statistics element_shadow">
            <div className="card-body text-center">
              <h3 className="stat-item-title fw-bold">{nFormatter(unpaid)}</h3>
              <label className="stat-item-subtitle fw-bold">Non payé</label>
            </div>
          </div>
        </div>

         <div className="col-md-3 mb-3">
          <div className="card   statistics element_shadow">
            <div className="card-body text-center">
              <h3 className="stat-item-title fw-bold">{nFormatter(parseFloat(unpaid) - parseFloat(balance))}</h3>
              <label className="stat-item-subtitle fw-bold">À payé</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
