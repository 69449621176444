import React, { useEffect } from "react";
import Button from "@mui/material/Button";

import { useTranslation } from "react-i18next";
export default function SendButton({onpress, disabled,title,icon}) {
  const { t } = useTranslation();
  return (
    <Button
           className={disabled ? "send_btn send_btn_disabled" : "send_btn"}
           disabled={disabled}
           onClick={() => {
             onpress();
           }}
        >
        {icon}
        {title}
    </Button>
  );
}
