import React, { useState, useEffect, useRef, useContext } from "react";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import Button from "@mui/material/Button";

export default function HistoricPrepaidItem({ data }) {
  return (
    <div className="col-md-12 mb-3">
      <div className="card w-100 transaction-item">
        <div className="card-body ">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="phone">{data.code}</div>
              <div>
                <span className="dealer ooredoo px-2">{data.type}</span>
              </div>
              <div className="transaction-date pt-2">
                DATE: {data.created_at}
                <span> SOLDE : {data.remaing_balance} DA</span>
              </div>
            </div>
            <div>
              <div className="amount text-end">{data.amount} DA</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
