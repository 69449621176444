import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from '@mui/material/Alert';

import { useTranslation } from "react-i18next";

export default function SwitchAccount({switchModalOpen,
 handelUserChoise
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={switchModalOpen}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={true}
      
    >
    <div className="switch_modal">
      <h2 className="text-center ">{"Choisir un type de compte"}</h2>
      
        <div className="row">
            <div className="col col-md-6">
                <div className="card switchaccount_btn" onClick={()=>{
                handelUserChoise('parti')
            }}>
                
                    <div className="card-body">
                        <img src="/images/parti.png" className="switch_img"/>
                        <p className="card-text ">Compte Particulier</p>
                </div>
                </div>
            </div>
            <div className="col col-md-6" >
                <div className="card switchaccount_btn" onClick={()=>{
                handelUserChoise('pro')
            }} >
                
                    <div className="card-body">
                        <img src="/images/prof.png" className="switch_img"/>
                        <p className="card-text ">Compte Professionnel</p>
                </div>
                </div>
            </div>
        </div>
       </div>
     
    </Dialog>
  );
}
