
import React, { useState, useEffect, useRef, useContext } from "react";

import Typography from '@mui/material/Typography';







const Infos = ({user}) => {
 
  
   
  return (

      <div className="info-page px-4">
        <Typography variant="h6" gutterBottom>
       Votre Informations
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Nom:      <strong>{user.name}</strong> 
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Téléphone: <strong>{user.phone}</strong> 
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Société:   <strong>{user.company}</strong> 
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Adresse:    <strong>{user.wilaya}/{user.region}</strong> 
      </Typography>
       
      </div>
    
  
  );
};

export default Infos;
