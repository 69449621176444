
import React, { useState, useEffect, useRef, useContext } from "react";





export default function Counter({nbrclients,totalpay,transferts}) {
  
   
 
  return (
<div className=" justify-content-center mb-5 resp_stats">
<div className="header bg-gradient-primary pb-8 justify-content-center">
  <div className="container-fluid justify-content-center">
   
    <div className="header-body">
      <div className="row justify-content-center">
        <div className="col-xl-3 col-lg-6">
          <div className="card card-stats mb-4 mb-xl-0">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="card-title text-uppercase text-muted mb-0">Clients</h5>
                  <span className="h2 font-weight-bold mb-0">{nbrclients}</span>
                </div>
                <div className="col-auto">
                  <div className="rounded-circle">
                   <img src="/images/user94.png" width={"60"}/>
                  </div>
                </div>
              </div>
              <p className="mt-3 mb-0 text-muted text-sm">
                <span className="text-success mr-2">Nombre total de clients</span>
              
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <div className="card card-stats mb-4 mb-xl-0">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="card-title text-uppercase text-muted mb-0">Transferts</h5>
                  <span className="h2 font-weight-bold mb-0">{transferts} </span>
                </div>
                <div className="col-auto">
                <div className="rounded-circle">
                   <img src="/images/money.png" width={"60"}/>
                  </div>
                </div>
              </div>
              <p className="mt-3 mb-0 text-muted text-sm">
                <span className="text-danger mr-2"> Total de Transferts (DZD)</span>
                
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <div className="card card-stats mb-4 mb-xl-0">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="card-title text-uppercase text-muted mb-0">Paiements</h5>
                  <span className="h2 font-weight-bold mb-0">{totalpay}</span>
                </div>
                <div className="col-auto">
                <div className="rounded-circle">
                   <img src="/images/payments.png" width={"60"}/>
                  </div>
                </div>
              </div>
              <p className="mt-3 mb-0 text-muted text-sm">
                <span className="text-warning mr-2">Total de paiements (DZD)</span>
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</div>
</div>
  );
}