import React, { useState, useEffect, useRef, useContext } from "react";

import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import { TransactionsContext } from "../constants/hoop";
import { UserContext } from "../providers/UserProvider";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import AddContact from "../components/flexy/AddContact";

export default function Home(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    transactionphone
  } = useContext(TransactionsContext);
  const { user, updateUserInfos } = useContext(UserContext);
  const [isOpen, setisOpen] = useState(false);
  //const { status, message, network } = props.route.params;
  const params = useLocation();

  // mobilis : 1
  // djezzy : 2
  // ooredoo : 3
  useEffect(() => {
    if (params.state.status) {
    
      updateUserInfos();
  
    }
  }, []);
const handelContactModal =()=>{
setisOpen(!isOpen)
}

  /**
   * Operator selection
   */
  const networkOperator = useRef(
    params.state.operator == 1 ? "06" : params.state.operator == 2 ? "07" : "05"
  );

  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />
      <div className="pt-100 container msg_status_container">
        <div className="card h-190 pb-5">
          <div className="card-body text-center h-190 mt-5">
            <img
              src={
                params.state.status ? "images/check.png" : "images/cross.png"
              }
              className="status_img"
            />
          </div>
          {params.state.status ? (
            <div className="alert alert-success msg_status" role="alert">
              Opération éffectué avec success <br></br>
              {params.state.msg}
            </div>
          ) : (
            <div className="alert alert-danger msg_status" role="alert">
              L'opération a échoué <br></br>
              {params.state.msg}
            </div>
          )}
         {!params.state.status &&( <Button
            variant="contained"
            className="btn_page mb-2"
            onClick={() => {
              if (params.state.isDjezzyFacture) { 
                navigate("/djezzyfacture/"+params.state.operator+"/false");
              } else {
                navigate("/flexy/"+params.state.operator+"/false");
              }
              
            }}
          >
            Refaire
          </Button>)}
          <Button
            variant="contained"
            className={"btn_page_addcontact"}
            onClick={() => {
             handelContactModal()
            }}
          >
            Ajouter aux contacts
          </Button>
          <Button
            variant="contained"
            className={params.state.status ? "btn_page" : "btn_page btn_page_musted"}
            onClick={() => {
              navigate("/");
            }}
          >
            Accueil
          </Button>
        </div>
      </div>
      <AddContact user={user} isOpen={isOpen} handelContactModal={handelContactModal} phone={networkOperator.current +  params.state.phone}/>
    </div>
  );
}
