import React, { useState, useEffect, useRef, useContext } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getamountPoints } from "../../helpers";
import Colors from "../../constants/Colors";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

export default function PricingButtons({
  handelbtnclick,
  disabled,
  available,
  amount,
  imgsource,pts,price
}) {
  const { t } = useTranslation();

  return (
    <Link
      href=""
      className="col-md-3 mb-3 "
      onClick={() => {
        if (!disabled) {
          handelbtnclick(amount);
        }
      }}
    >
      <div className="card position-relative pricing_card">
        <div className="card-body text-center p-1 element_shadow">
          <img src={imgsource} alt="" className="w-100 rounded-1" />
        </div>
        {pts != null &&(
          <span className="points">
          
          <img src={'/images/pts_gift.png'}  className="pts_icon" width={'25'} />
          {pts} DA
        </span>
        )}
        {available == 0 ? (
          <>
            <div className="overlay"></div>
            <span className="unavailable-status">Indisponible</span>
          </>
        ) : null}
        {price != null &&(<div className="games_price">
          {price} DA
        </div>)}
      </div>
    </Link>
  );
}
