import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useReducer,
  createContext
} from "react";

import AppNavigation from "../navigation/AppNavigation";
import AuthNavigation from "../navigation/AuthNavigation";

import { TWS } from "../services/WebServices";
import Loading from "../components/Loading";
import Login from "../pages/auth/Login";
export const AuthContext = createContext({});


const AuthStack = props => {
 
  const [authState, authDispatch] = useReducer(
    (currentState, action) => {
      switch (action.type) {
        case "SIGN_IN":
          TWS.setToken(action.token);
          localStorage.setItem("token", action.token);

          return {
            token: action.token,
            isLoading: false,
            user: null
          };

        case "SIGN_OUT":
          TWS.setToken(null);
          localStorage.removeItem("token");

          return {
            token: null,
            isLoading: false,
            user: null
          };
      }
      return currentState;
    },
    {
      isLoading: true,
      token: null
    }
  );

  TWS.onUnauthorized = () => {
    authDispatch({ type: "SIGN_OUT" });
  };
  useEffect(() => {
    asyncLocalStorage.getItem("token").then(token => {
      if (token === null) authDispatch({ type: "SIGN_OUT" });
      else authDispatch({ type: "SIGN_IN", token });
    });
  }, []);

  const asyncLocalStorage = {
    setItem: function(key, value) {
      return Promise.resolve().then(function() {
        localStorage.setItem(key, value);
      });
    },
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };

  if (authState.isLoading) return <Loading />;
  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {authState.token ? <AppNavigation /> : <Login />}
    </AuthContext.Provider>
  );
};

export default AuthStack;
