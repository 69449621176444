import React, { useState} from "react";

import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';

import FilledInput from '@mui/material/FilledInput';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import { TWS } from "../../services/WebServices";
import SuccessDialog from "../../components/SuccessDialog";





const Client = ({user}) => {
 

  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);

  const [showErrors, setshowErrors] = useState(false);
  const [sucessStatus, setSuccessStatus] = useState(false);
  


function validation(){
 
  if(phone === null || name === null || phone.length < 10 ) {
    setshowErrors(true)
    }else{
    
      postCustomer()
    }
}

  const postCustomer = async () => {
    
    try {
      
      await TWS.postCustomer(name,phone,user.id,user.token).then(res => {
        handelsucessdialog()
        setshowErrors(false)
      });
  
    } catch (errors) {
      setshowErrors(true)
      //alert("une erreur est survenue veuillez réessayer ultérieurement");
    }
  
}
const handelsucessdialog = () => setSuccessStatus(!sucessStatus);


  return (

      <div className="pass-page">
      <SuccessDialog title={null} message='Opération effectuée avec succès' handelsucessdialog={handelsucessdialog} status={sucessStatus}/>
      {showErrors && ( <Stack sx={{ width: '95%' ,pl:2, pr:4 ,pb:4}} spacing={2}>
      <Alert severity="error">
        <AlertTitle>Erreur</AlertTitle>
        Le numéro de téléphone ou le nom n'est pas valide <br></br>
      </Alert>
    </Stack>)}
     
      <div className="row px-4">
    <div className="settings">
      <div>
      <Typography variant="h6" gutterBottom>
       Ajouter un contact
      </Typography>
    
    <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
          <InputLabel htmlFor="filled-adornment-name">Nom</InputLabel>
          <FilledInput
            id="filled-adornment-name"
            type={'text'}
            onChange={event => {
              setName(event.target.value);
            }}
            
           
            endAdornment={
              <InputAdornment position="end">
                 <PersonIcon /> 
              </InputAdornment>
            }
          />
        </FormControl>
        </div>
        <div>
        <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
          <InputLabel htmlFor="filled-adornment-phone">Téléphone</InputLabel>
          <FilledInput
            id="filled-adornment-phone"
            type={'number'}
            onChange={event => {
              setPhone(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
               <PhoneIcon /> 
              </InputAdornment>
            }
          />
          
        </FormControl>
        </div>
       
        <Button
              onClick={() => { 
                validation()
              }}
              fullWidth
              variant="contained"
              sx={{ ml:1, mt: 2, mb: 2, height: 45, width: '40ch'  }}
              className="pass_btn"
              
            >
              Enregistrer
            </Button>
          </div>
        </div>
       
      </div>
    
  
  );
};

export default Client;
