import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/UserProvider";
import { TWS } from "../../services/WebServices";


const Tuto = props => {
  const { t } = useTranslation();
  const { user} = useContext(UserContext);
    const [Videos, setVideos] = React.useState([]);
  useEffect(() => {

  getVideosList()

    }, []);
function getVideosList(){
    TWS.getVideos()
      .then(res => {
        setVideos(res);
      
 
      })
      .catch(() => {});
}
  return (
    <div className="wrapper">
     <Navbar active="tutorials" props={props} data={user} />
      <div className="row tuto">
       
       
        {Videos.map((row) => (
        <div key={row.title} className="col-md-6 tuto_card">
            <iframe  src={row.link} title={row.title} width="100%" height={"100%"} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>       
            <h6 className="tuto_title">{row.title}</h6>
       </div>
        ))}
      

      </div>  
      
    </div>
  );
};

export default Tuto;
