import { createContext, useContext, useEffect, useRef, useState } from "react";
import { TWS } from "../services/WebServices";

import Loading from "../components/Loading";
export const UserContext = createContext({});

const UserProvider = props => {
  const [user, setUser] = useState(null);
  const [stats, setStats] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [mobilisstatus, setmobilisstatus] = useState(null);
  const [djezzystatus, setdjezzystatus] = useState(null);
  const [ooredoostatus, setooredoostatus] = useState(null);
 
  const updateUserInfos = () => {
    TWS.getAuthenticatedUser()
      .then(user => {
        setStats(user.stats);
        setUser(user.user);
       
        
        setmobilisstatus(user.mobilisstatus);
        setdjezzystatus(user.djezzystatus);
        setooredoostatus(user.ooredoostatus);
        if (user.user.status != "Active") {
          TWS.onUnauthorized();
        }
      })
      .catch(() => {
        TWS.onUnauthorized();
      });
  };

  useEffect(() => {
    updateUserInfos();
  }, []);

  if (!user) return <Loading />;

  return (
    <UserContext.Provider
      value={{
        user,
        stats,
        updateUserInfos,
        setDeviceId,
        deviceId,mobilisstatus,ooredoostatus,djezzystatus
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
