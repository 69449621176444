import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
export default function SoldeStatus(props) {
  const { t } = useTranslation();
  return (
    <div className="container ">
      
      <div className="alert alert-danger sold_status" role="alert">
        .وصل رصيدك إلى مستوى منخفض اتصل بنا لاعادة شحن حسابك
      </div>
      
    </div>
  );
}
