import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/UserProvider";
import { TWS } from "../../services/WebServices";
import ErrorDialog from "../../components/ErrorDialog";
import PricingButtons from "../../components/internet/PricingButtons";
import Confirmation from "../../components/Confirmation";
import SelectedGameCard from "../../components/games/SelectedGameCard";
import SendLoading from "../../components/flexy/SendLoading";

const GamesCards = props => {
  const { t } = useTranslation();
  const { operator } = useParams();
  const [selectedoffervisible, setselectedoffervisible] = useState(false);
  const [cardNumber, setcardNumber] = useState("");
  const [cardPts, setcardPts] = useState(0);
  const [dataloading, setdataloading] = useState(true);
  const [cardNumberloading, setcardNumberloading] = useState(false);
  const [pts, setpts] = useState([0,0,0]);
  const [selectedamount, setselectedamount] = useState(0);
  const [errordialogMessage, seterrordialogMessage] = useState("");
  const [errordialog, seterrordialog] = useState(false);
  const [deviceId, setDeviceId] = useState(null);

  const [smsresponse, setsmsresponse] = useState(false);
  const { user } = useContext(UserContext);
  /**
   * Operator selection
   */
  const networkOperator = useRef(
    operator == 1 ? "PUBG" :"FREEFIRE"
  );
  /**
   * get device id
   */
  const asyncLocalStorage = {
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        setDeviceId(JSON.parse(deviceId));
      }
    });
  }, []);

  /**
   *  get card number
   */

  function getCardNumber(pts) {
    console.log(pts)
    TWS.getGameCardNumber(
      user.id,
      pts,
      networkOperator.current,
      user.token
    )
      .then(res => {
        console.log(res)
        setcardNumber(res.code);
        //setcardPts(res.pts);
        setcardNumberloading(false);
        setselectedoffervisible(true);
      })
      .catch(() => {});
  }
  /**
   * check cards
   */

    const [checkList, setcheckList] = useState([]);
    useEffect(() => {
    TWS.checkGameCards(networkOperator.current, user.token)
      .then(res => {
        console.log(res)
        setcheckList(res.data)
        setdataloading(false);
      })
      .catch(() => {});
  }, []);


// Function to find price for points 60
function findPriceForPoints(pts) {
    // Find the object with points 60
    const obj = checkList.find(item => item.points === pts);
    // If the object exists, return its price, otherwise return null
    return obj ? obj.price : 0;
}
// pts  = utc in pubg and diamands in freefire
  const handelBtnClick = pts => {
    const priceForPoints = findPriceForPoints(pts);

    if (
      user.activebalance[0].balance == 0 ||
      user.activebalance[0].balance < parseInt(priceForPoints)
    ) {
      seterrordialog(true);
      seterrordialogMessage("Solde insuffisant,merci de charger votre compte");
    } else {
      ButtonAlert(pts);
    }
  };

  /**
   *
   * buy card
   */

  function buyCard() {
    //setcardNumberloading(true);
    //setselectedamount(amount);
    getCardNumber(selectedamount);
  }

  // pts here represente utc and diamands
  const ButtonAlert = (pts) => {
  
    setselectedamount(pts);
   handleConfirmation(true);
  };
  const handelErrorDialog = value => {
    seterrordialog(value);
  };

  const [openconfimation, setopenconfimation] = useState(false);
  const handleConfirmation = value => {
    setopenconfimation(value);
  };
  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />
      {/* main */}
      <div className={operator == 1 ? "main-content pb-5 idoom-page games_page_pubg" : "main-content pb-5 idoom-page games_page_fire"}>
        <div className="container">
          <h1 className="pagetitle_intern text-center text-white">
            Cartes {networkOperator.current}
          </h1>
          <ErrorDialog
            errordialog={errordialog}
            handelerrordialog={handelErrorDialog}
            message={errordialogMessage}
          />
          <Confirmation
            handleConfirmation={handleConfirmation}
            openconfimation={openconfimation}
            msg={"Vous voulez acheter une carte de recharge ?"}
            method={buyCard}
          />
          <SendLoading loading={cardNumberloading} />

          {selectedoffervisible ? (
            <div className="row justify-content-center">
              <SelectedGameCard
                cardnumber={cardNumber}
                amount={parseInt(selectedamount)}
                type={networkOperator.current}
               
              />
            </div>
          ) : (
            <div className="row justify-content-center">
              <div className="row justify-content-center my-2">
               {/** <div
                  class="alert alert-info text-end col-md-8 text-center"
                  role="alert"
                >
                  .يمكنك طلب كمية مطبوعة من بطاقات التعبئة و التوصيل مجاني للمحل
                </div>*/} 
              </div>
              <div className="row justify-content-center">
               {checkList.length != 0 ? (
                <>
                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={checkList[0].count === 0 ? true : false}
                  available={checkList[0].count}
                  amount={checkList[0].points}
                  imgsource={
                    operator == 2
                      ? "/images/freefire1.jpg"
                       : "/images/pubg1.jpg"
                  }
                  pts={null}
                  price={checkList[0].price}
                />
                

                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={checkList[1].count === 0 ? true : false}
                  available={checkList[1].count}
                  amount={checkList[1].points}
                  imgsource={
                     operator == 2
                      ? "/images/freefire2.jpg"
                       : "/images/pubg2.jpg"
                  }
                  pts={null}
                  price={checkList[1].price}
                />
                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={checkList[2].count === 0 ? true : false}
                  available={checkList[2].count}
                  amount={checkList[2].points}
                  imgsource={
                    operator == 2
                      ? "/images/freefire3.jpg"
                       : "/images/pubg3.jpg"
                  }
                  pts={null}
                  price={checkList[2].price}
                />
                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={checkList[3].count === 0 ? true : false}
                  available={checkList[3].count}
                  amount={checkList[3].points}
                  imgsource={
                    operator == 2
                      ? "/images/freefire4.jpg"
                       : "/images/pubg4.jpg"
                  }
                  pts={null}
                  price={checkList[3].price}
                />
                </>
                ): null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GamesCards;
