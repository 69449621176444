import React, { useState, useEffect, useRef, useContext } from "react";

import { useTranslation } from "react-i18next";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Colors from "../../constants/Colors";

export default function Offerdescription({
  data
}) {
  const { t } = useTranslation();
 


  return (
    <>
    {data != null && (
      <List sx={{ width: '90%', bgcolor: 'background.paper' }} className="offerdescp">
    {data.map( (item, i) =>{
    return (
      <ListItem key={i} >
        <ListItemAvatar>
         
            <img src={'/images/ic'+i+'.png'}  className="pts_icon" width={'25'} />
         
        </ListItemAvatar>
        <ListItemText primary={item}  />
      </ListItem>
    )
    })}
      
    </List>)}
    </>
  );
}
