import React, { useState, useEffect, useRef, useContext } from "react";

import Button from "@mui/material/Button";
import { nFormatter } from "../../helpers";
export default function CustomerRecharge({data}) {



 
  return (
    <div className="col-md-12 mb-3 "  >
      
      <div className={"card rechargement_card transaction-item " } >
        
      <div className="row ">
            <p className="text-end mb-0">{data.created_at}</p>
          </div>
        <div className={parseInt(data.unpaid) > 0  ? " card-body recharge_pro unpaid_row": " card-body recharge_pro paid_row" }>
         
          <div className=" row d-flex align-items-center justify-content-between">
            <div className="text-center col-sm-3">
              <div className="recharge_item  text-center">
              <p className="recharge_item_name">{data.client.name}</p>
              <p className="recharge_item_pro_title">{data.client.phone}</p>
              </div>
            </div>
            <div className="text-center col-sm-3">
              <div className="recharge_item text-center">
              <p className="recharge_item_pro_amount primary"> {nFormatter(data.balance)} DA</p>
              <p className="recharge_item_pro_title">Rechargement</p>
              </div>
            </div>

            <div className="text-center col-sm-3">
              <div className="recharge_item text-center">
              <p className="recharge_item_pro_amount green">{nFormatter(data.paid)} DA</p>
              <p className="recharge_item_pro_title ">Payé</p>
              </div>
            </div>

            <div className="text-center col-sm-3">
              <div className="recharge_item text-center">
              <p className="recharge_item_pro_amount red">{nFormatter(data.unpaid)} DA</p>
              <p className="recharge_item_pro_title">No payé </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}
