import React, { useState, useEffect, useRef, useContext } from "react";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import Typography from '@mui/material/Typography';
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import Colors from "../../constants/Colors";
import { editUssdforResponse,
  getAmountFromSelectedChoise,
  removeOfferFromResponse,
 } from "../../helpers";
import Confirmation from "../Confirmation";


export default function BottomDrawerModal({
   dataloading,
  showmodelresponse,
  handelmodalresponse,
  data,
  handelussdformodalresponse,
  ussd,
  operator,
}) {
  const { t } = useTranslation();
 let [loadingresponseModal, setloadingresponseModal] = useState(false);
  let [selectedItem, setselectedItem] = useState(null);
  const [openconfimation, setopenconfimation] = useState(false);

  


  const sendUssdCodeForSelectedChoice =() =>{
    setloadingresponseModal(true);
   // setselectedItem(item);
  

    handelussdformodalresponse(
      editUssdforResponse(ussd, selectedItem, operator),
      getAmountFromSelectedChoise(selectedItem),
      selectedItem,
    );
    
  }

 const ButtonAlert = (item) => {
  setselectedItem(item);
    handleConfirmation(true);
  };
  const handleConfirmation = value => {
    setopenconfimation(value);
  };


  return (
   <>
    <Dialog open={showmodelresponse} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>Les Offres disponibles sont : </DialogTitle>
      <DialogContent>
        {loadingresponseModal ? (
           <Box sx={{ width: '100%' }} className="OfferModalPending">
            <CircularProgress size={30} color="secondary"/>
            <Typography variant="h6"  gutterBottom>
              {selectedItem} est en cours de traitement
            </Typography>
          </Box>
          
        ):(
        <div>
        {data.response && (
          <List dense={true}>
            {data.response.map((item, index) => {
              return removeOfferFromResponse(item) != null ? (
                <ListItemButton  className="Modaloffer" key={index} onClick={()=>{
                  
                  ButtonAlert(item)
                }}>
                  <ListItemText
                    primary={item}
                    primaryTypographyProps={{
                      fontSize: 20,
                      fontWeight: "bold",
                      letterSpacing: 0,
                      backgroundColor: "#f2f2f2",
                      borderRadius: 1,
                      paddingX: 1,
                      paddingY: 1,
                      color: "#626262"
                    }}
                  />
                </ListItemButton>
              ) : null;
            })}
          </List>
        )}
        </div>)}
      </DialogContent>
      <DialogActions>
        <Button
          className="annuler_btn"
          onClick={() => {
            handelmodalresponse(false);
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
    <Confirmation
        handleConfirmation={handleConfirmation}
        openconfimation={openconfimation}
        msg={" Voulez-vous continuer ?"}
        method={sendUssdCodeForSelectedChoice}
      />
    </>
  
  );
}
