import React, { useState, useEffect, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import SendLoading from "./SendLoading";
import Confirmation from "../Confirmation";
import Button from "@mui/material/Button";
export default function FloatingButton({
  sendUssdCode,
  color,
  loading,
  disabled
}) {
  const [openconfimation, setopenconfimation] = useState(false);
  const ButtonAlert = () => {
    handleConfirmation(true);
  };
  const handleConfirmation = value => {
    setopenconfimation(value);
  };
  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      {/*<Fab
        variant="extended"
        className={disabled ? "send_btn send_btn_disabled" : "send_btn"}
        disabled={disabled}
        onClick={() => {
          ButtonAlert();
        }}
      >
        <NavigationIcon sx={{ mr: 1 }} />
        Envoyer
      </Fab>*/}
      <Button
           className={disabled ? "send_btn send_btn_disabled" : "send_btn"}
           disabled={disabled}
           onClick={() => {
             ButtonAlert();
           }}
        >
           <NavigationIcon sx={{ mr: 1 }} />
        Envoyer
        </Button>
      <SendLoading loading={loading} />
      <Confirmation
        handleConfirmation={handleConfirmation}
        openconfimation={openconfimation}
        msg={" Voulez-vous continuer ?"}
        method={sendUssdCode}
      />
    </Box>
  );
}
