import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../providers/UserProvider";
import { TWS } from "../services/WebServices";
import ErrorDialog from "../components/ErrorDialog";
import PricingButtons from "../components/internet/PricingButtons";
import Confirmation from "../components/Confirmation";
import SelectedPrepaidCard from "../components/prepaidcard/SelectedPrepaidCard";
import SendLoading from "../components/flexy/SendLoading";

const PrepaidCards = props => {
  const { t } = useTranslation();
  const { operator } = useParams();
  const [selectedoffervisible, setselectedoffervisible] = useState(false);
  const [cardNumber, setcardNumber] = useState("");
  const [cardPts, setcardPts] = useState(0);
  const [dataloading, setdataloading] = useState(true);
  const [cardNumberloading, setcardNumberloading] = useState(false);
  const [oneHundred, setOneHundred] = useState(0);
  const [cardFifty, setcardFifty] = useState(0);
  const [cardThousand, setcardThousand] = useState(0);
  const [cardtwoThousand, setcardtwoThousand] = useState(0);
  const [pts, setpts] = useState([0,0,0]);
  const [selectedamount, setselectedamount] = useState(0);
  const [errordialogMessage, seterrordialogMessage] = useState("");
  const [errordialog, seterrordialog] = useState(false);
  const [OverlayVisible, setOverlayVisible] = useState(false);
  const [sendwith, setsendwith] = useState("");
  const [deviceId, setDeviceId] = useState(null);

  const [smsresponse, setsmsresponse] = useState(false);
  const { user } = useContext(UserContext);
  /**
   * Operator selection
   */
  const networkOperator = useRef(
    operator == 1 ? "Mobilis" : operator == 2 ? "Djezzy" : "Ooredoo"
  );
  /**
   * get device id
   */
  const asyncLocalStorage = {
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        setDeviceId(JSON.parse(deviceId));
      }
    });
  }, []);

  /**
   *  get card number
   */

  function getCardNumber(amount) {
    TWS.getPrepaidCardNumber(
      user.id,
      amount,
      networkOperator.current,
      user.token
    )
      .then(res => {
        setcardNumber(res.code);
        setcardPts(res.pts);
        setcardNumberloading(false);
        setselectedoffervisible(true);
      })
      .catch(() => {});
  }
  /**
   * check cards
   */
  useEffect(() => {
    TWS.checkPrepaidCards(networkOperator.current, user.token)
      .then(res => {
        
        setpts([res.f_pts.pts, res.t_pts.pts, res.tt_pts.pts])

        setcardFifty(res.fifty);
        setcardThousand(res.thousand);
        setOneHundred(res.onehundred);
        setcardtwoThousand(res.twothousand)
        setdataloading(false);
      })
      .catch(() => {});
  }, []);

  const handelBtnClick = amount => {
    if (
      user.activebalance[0].balance == 0 ||
      user.activebalance[0].balance < parseInt(amount)
    ) {
      seterrordialog(true);
      seterrordialogMessage("Solde insuffisant,merci de charger votre compte");
    } else {
      ButtonAlert(amount);
    }
  };

  /**
   *
   * buy card
   */

  function buyCard() {
    setcardNumberloading(true);
    //setselectedamount(amount);
    getCardNumber(selectedamount);
  }
  const ButtonAlert = amount => {
    setselectedamount(amount);
    handleConfirmation(true);
  };
  const handelErrorDialog = value => {
    seterrordialog(value);
  };

  const [openconfimation, setopenconfimation] = useState(false);
  const handleConfirmation = value => {
    setopenconfimation(value);
  };
  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />
      {/* main */}
      <div className="main-content pb-5 idoom-page">
        <div className="container pt-100">
          <h1 className="pagetitle_intern text-center">
            Cartes de recharge {networkOperator.current}
          </h1>
          <ErrorDialog
            errordialog={errordialog}
            handelerrordialog={handelErrorDialog}
            message={errordialogMessage}
          />
          <Confirmation
            handleConfirmation={handleConfirmation}
            openconfimation={openconfimation}
            msg={"Vous voulez acheter une carte de recharge ?"}
            method={buyCard}
          />
          <SendLoading loading={cardNumberloading} />

          {selectedoffervisible ? (
            <div className="row justify-content-center">
              <SelectedPrepaidCard
                cardnumber={cardNumber}
                amount={parseInt(selectedamount)}
                type={networkOperator.current}
                pts={cardPts}
              />
            </div>
          ) : (
            <div className="row">
              <div className="row justify-content-center my-4">
               {/** <div
                  class="alert alert-info text-end col-md-8 text-center"
                  role="alert"
                >
                  .يمكنك طلب كمية مطبوعة من بطاقات التعبئة و التوصيل مجاني للمحل
                </div>*/} 
              </div>
              <div className="row justify-content-center">
                
                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={cardFifty === 0 ? true : false}
                  available={cardFifty}
                  amount={500}
                  imgsource={
                    operator == 1
                      ? "/images/carte500mobilis.png"
                      : operator == 2
                      ? "/images/carte500djezzy.png"
                      : "/images/carte500ooredoo.png"
                  }
                  pts={pts[0]}
                  price={null}
                />

                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={cardThousand === 0 ? true : false}
                  available={cardThousand}
                  amount={1000}
                  imgsource={
                    operator == 1
                      ? "/images/carte1000mobilis.png"
                      : operator == 2
                      ? "/images/carte1000djezzy.png"
                      : "/images/carte1000ooredoo.png"
                  }
                  pts={pts[1]}
                  price={null}
                />
                <PricingButtons
                  handelbtnclick={handelBtnClick}
                  disabled={cardtwoThousand === 0 ? true : false}
                  available={cardtwoThousand}
                  amount={2000}
                  imgsource={
                    operator == 1
                      ? "/images/carte2000mobilis.png"
                      : operator == 2
                      ? "/images/carte1000djezzy.png"
                      : "/images/carte1000ooredoo.png"
                  }
                  pts={pts[2]}
                  price={null}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrepaidCards;
