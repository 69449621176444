import React, { useState, useEffect, useContext ,useRef} from "react";

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { UserContext } from "../../providers/UserProvider";
import { TWS } from "../../services/WebServices";


export default function ContactDrawer({handelSelectedContact,operator}) {
  const [state, setState] = React.useState({
    right: false,
  });


  const [contactlist, setcontactlist] = useState([]);
  const [loading, setloading] = useState(true);
  const {user} = useContext(UserContext);

  const network = useRef(operator == 1 ? '06' : operator == 2 ? '07' : '05');


  useEffect(() => {
    getCustomers();
  }, []);

   function getCustomers() {
    setloading(true);
    TWS.getCustomersList(user.id, user.token)
      .then(res => {
        setcontactlist(res.data);
        setloading(false);
        
      })
      .catch(() => {
        //TWS.onUnauthorized();
      });
  }

    const handlInputSearch = keyword => {
    if (keyword.length > 0) {
      setloading(true);

      TWS.getCustomerWithNameOrPhone(user.id, keyword, user.token)
        .then(res => {
          setcontactlist(res);
          setloading(false);
        })
        .catch(() => {
          //TWS.onUnauthorized();
        });
    } else {
      getCustomers();
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
      role="presentation"
     
    >
      
       <div className="row justify-content-center">
         <div className="row ">
            <div className="col-12 my-2 px-2">
                 <Typography variant="h6" gutterBottom>
                   Contacts
                </Typography>
            </div>
             <div className="col-12 my-2 px-2">
               <TextField
                size="small"
                id="outlined-number"
                label="Rechercher"
                type="text"
                 sx={{width:'100%', background:'#fff'}}
                InputLabelProps={{
                     shrink: true,
                            
                }}
                placeholder="Cherchez par nom ou numéro"
                onChange={event => {
                           handlInputSearch(event.target.value); 
                     }}
                />
                 </div>
            </div>
        </div>
      <Divider />
    {loading ? (<Stack  justifyContent="center" alignItems="center" direction="row" sx={{marginTop:5, marginBottom:5}}>
                  <CircularProgress size={30} sx={{ color:'#A343FA' }}/>
                </Stack>) :(
      <List>
        {contactlist.map((el, index) => (
          <ListItem key={index} disablePadding onClick={toggleDrawer(anchor, false)}>
            <ListItemButton  disabled={network.current == el.phone.substring(0,2) ? false : true} onClick={()=>{
             
              handelSelectedContact(el.phone.slice(2),el.customer)
              
            }}>
              
              <ListItemText primary={el.customer}  secondary={el.phone}/>
              
              <span className={ el.phone.substring(0,2) =='06' ? 'contact_badge mobilis' : el.phone.substring(0,2) == '07' ? 'contact_badge djezzy' : 'contact_badge ooredoo'}>
                {el.phone.substring(0,2) =='06' ? 'Mobilis' : el.phone.substring(0,2) == '07' ? 'Djezzy' : 'Ooredoo'}
              </span>
            </ListItemButton>
          </ListItem>
        ))}
      </List>)}
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button className='contactbtn' onClick={toggleDrawer(anchor, true)}>{'Contacts'}</Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}