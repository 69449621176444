import React from "react";


const Notfound = () => {
    return(
        <div className="container text-center pt-100">
            <h3>404 | Not Found</h3>
        </div>
    )
}

export default Notfound;