import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../providers/UserProvider";
import { TWS } from "../services/WebServices";
import Stats from "../components/rechargement/Stats";
import SendLoading from "../components/flexy/SendLoading";
import RechargeItem from "../components/rechargement/RechargeItem";
import TicketsRecharge from "../components/rechargement/TicketsRecharge";
import TabList from "@mui/lab/TabList";
import Tab from '@mui/material/Tab';
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Professional from "../components/rechargement/ProAccount";
import Loading from "../components/Loading";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Rechargement = props => {
  const { t } = useTranslation();

  const [deviceId, setDeviceId] = useState(null);
  const [loading, setloading] = useState(true);
  const [list, setlist] = useState([]);
  const [prepaidcardslist, setprepaidcardslist] = useState([]);
  
  const [unpaid, setunpaid] = useState(0);
  const { user } = useContext(UserContext);
  const [mergeddata, setmergeddata] = useState([]);
  const [tabindex, settabindex] = useState("1");

  const handleChange = (event, newValue) => {
    settabindex(newValue);
  };
/**
 * compte particulier et pro
 */
  const [pro, setPro] = React.useState(1);
  

  const handleChangeToPro = (event) => {
    setPro(event.target.value);
    if(event.target.value == 2){
      asyncLocalStorage.setItem("ispro", JSON.stringify('pro'));
       if (window && window.Tawk_API) {
            window.Tawk_API.hideWidget();
          }

    }else{
      asyncLocalStorage.setItem("ispro", JSON.stringify('prti'));
      
    }
  };
  /**
   * get device id
   */
  const asyncLocalStorage = {
     setItem: function(key, value) {
      return Promise.resolve().then(function() {
        localStorage.setItem(key, value);
      });
    },
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        setDeviceId(JSON.parse(deviceId));
      }
    });
  }, []);

  /**
   * GET RECHARGE LIST
   */

  useEffect(() => {
    TWS.getRechargments(user.id, user.token)
      .then(res => {
        var sum = 0;
        
        setmergeddata(res.mergeddata);
        setprepaidcardslist(res.prepaidcards)
        setlist(res.data);
        res.data.forEach(element => {
          sum += parseInt(element.unpaid);
        });

        setunpaid(sum);
        setloading(false);
      })
      .catch(() => {
        TWS.onUnauthorized();
      });
  }, []);

  // switch account
 useEffect(() => {
    asyncLocalStorage.getItem("ispro").then(resp => {
   
      if (JSON.parse(resp) == "pro") {
        setPro(2)
        if (window && window.Tawk_API) {
            window.Tawk_API.hideWidget();
          }
        
      }
    });
  }, []);



  return (
    <div className="wrapper">
      <Navbar active="rechargements" props={props} data={user} />
      {/* main */}
       
      <div className="main-content pb-5 ">
        <div className="clearfix px-5">
          <Box sx={{ minWidth: 180 }} className="float-sm-end">
      <FormControl fullWidth className="accountType">
        <InputLabel sx={{ minWidth: 180 }}  id="demo-simple-select-label">Compte</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="select-account"
          value={pro}
          label="Compte"
          onChange={handleChangeToPro}
        >
          <MenuItem value={1}>Particulier</MenuItem>
          <MenuItem value={2}>Professionnel</MenuItem>
         
        </Select>
      </FormControl>
    </Box>
        </div>
      {pro == 1 ? (
      <TabContext value={tabindex}>
    
      <TabList
          onChange={handleChange}
          centered>
            <Tab label="Recharges" value="1" />
            <Tab label="Tickets"  value="2" />
            <Tab label="Historique"  value="3" />  
      </TabList>

      <TabPanel value={"1"} >
        <div className="container  px-8">
            <SendLoading loading={loading} />
            <Stats balance={user.activebalance[0].balance} unpaid={unpaid} />
            <h1 className="pagetitle_intern text-center mt-4">
              Rechargements
            </h1>
            {list.map((item, index) => {
              return <RechargeItem key={index} data={item} />;
            })}
            
        </div>
      </TabPanel>
      <TabPanel value={"2"} >
       {prepaidcardslist.length > 0 &&( <div className="container  px-8">
            
            <h1 className="pagetitle_intern text-center mt-4">
              Tickets
            </h1>
            {prepaidcardslist.map((item, index) => {
              return <TicketsRecharge key={index} data={item} />;
            })}
            
        </div>)}
      </TabPanel>
      <TabPanel value={"3"} >
        <div className="container  px-8">
            <SendLoading loading={loading} />
            <Stats balance={user.activebalance[0].balance} unpaid={unpaid} />
            <h1 className="pagetitle_intern text-center mt-4">
              Paiements
            </h1>
            {mergeddata.map((item, index) => {
              return <RechargeItem key={index} data={item} />;
            })}
            
        </div>
      </TabPanel>
      </TabContext>
      ) :(
      user.category != 1 ? (<Loading/>) :( <Professional user={user}/>)
      )}
        </div>
       
    </div>
  );
};

export default Rechargement;
