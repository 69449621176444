import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/UserProvider";
import Typography from '@mui/material/Typography';


import Passwords from "../../components/settings/Passwords";
import Divider from '@mui/material/Divider';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Infos from "../../components/settings/Infos";
import Client from "../../components/settings/Client";
import FeedIcon from '@mui/icons-material/Feed';
import LockResetIcon from '@mui/icons-material/LockReset';
import PeopleIcon from '@mui/icons-material/People';

const Settings = props => {
  const { t } = useTranslation();
  const { user} = useContext(UserContext);
  const [slectedMenu, setslectedMenu] = useState(1);

const handelClick =(selected)=>{
      setslectedMenu(selected)
}

  return (
    <div className="wrapper">
      <Navbar active="settings" props={props} data={user} />
      <div className="row settings-menu">
      <div className="col-md-4">
        <MenuList>
          <Typography sx={{ marginBottom:3}} variant="h6">
            Paramètres
          </Typography>
          <Divider/>
        <MenuItem className="menu_items" selected={slectedMenu == 1 ? true :false}  onClick={() => {
          handelClick(1)
        }}>
          <ListItemIcon>
            <FeedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Informations</ListItemText>
        
        </MenuItem>
        <MenuItem className="menu_items" selected={slectedMenu == 2 ? true :false}  onClick={() => { handelClick(2)}}>
          <ListItemIcon>
            <LockResetIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mot de passe</ListItemText>
        
        </MenuItem>
        <MenuItem className="menu_items" selected={slectedMenu == 3 ? true :false}  onClick={() => { handelClick(3)}}>
          <ListItemIcon>
            <PeopleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Contacts</ListItemText>
        
        </MenuItem>
        
      </MenuList>
      </div>
      <div className="col-md-8">
        {slectedMenu == 1 ? ( <Infos user={user}/>): slectedMenu == 2 ? ( <Passwords user={user}/>) : (<Client user={user}/>)}
       
      </div>
    </div>
      
    </div>
  );
};

export default Settings;
