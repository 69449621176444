import React, { useState, useEffect, useRef, useContext } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { getamountPoints } from "../../helpers";
import Colors from "../../constants/Colors";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SmsIcon from "@mui/icons-material/Sms";
import Recharge from "./Recharge";
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectedCard({
  cardnumber,
  amount,
  type,
  link,
  pts,
  handeloverlay,
  smsresponse,
  cardImg
}) {
  const { t } = useTranslation();
  const [openrechage, setopenrechage] = useState(false);
  const handelerecharge = value => {
    setopenrechage(value);
  };

   const [openImage, setOpenImage] = useState(false);

  const handleClickOpenImage = () => {
    setOpenImage(true);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

const URL = 'https://mdash.rminov.com/images/'

  return (
    <>
      <div className="card position-relative internet_card">
        <Badge color="secondary" badgeContent={pts + " Points"}></Badge>
        
        <div className="card-body text-center ">
          {type == "ADSL" ? (
           <Button onClick={handleClickOpenImage} className="card_img_btn"> <img
              src={cardImg != null ? URL+cardImg
                : amount == 500
                  ? "images/idoom_card_500da.png"
                  : amount == 1000
                  ? "images/idoom_card_1000da.png"
                  : "images/idoom_card_2000da.png"
              }
              alt=""
              className="card_img"
            /></Button>
          ) : (
            <Button onClick={handleClickOpenImage} className="card_img_btn">  <img
              src={cardImg != null ? URL+cardImg
                : amount == 500
                  ? "images/idoom4g_500da.png"
                  : amount == 1000
                  ? "images/idoom4g_1000da.png"
                  : amount == 1500 ?
                  "images/idoom4g_1500da.png"
                  : "images/idoom_card_2500da.png"
              }
              alt=""
              className="card_img"
            /></Button>
          )}
        </div>
      </div>
      <div className="text-center mt-4 ">
        <Typography variant="h4" gutterBottom>
          {cardnumber}
        </Typography>
      </div>
      <div className="text-center mt-4 ">
        <Button
          startIcon={<CreditCardIcon />}
          variant="contained"
          target="_blank"
          component="a"
          href={link}
        >
          Rechargement en ligne
        </Button>
      </div>
      <div className="text-center mt-4 ">
        <Button
          startIcon={<SmsIcon />}
          variant={"contained"}
          onClick={() => {
            handeloverlay("SMS");
          }}
        >
          {smsresponse ? "Message envoyé" : "Envoyer via sms"}
        </Button>
      
      </div>
      {/*<Recharge
        openrechage={openrechage}
        handelerecharge={handelerecharge}
        link={link}
      />*/}
      <div className="text-center mt-4 ">
        <Button startIcon={<LocalPrintshopIcon />} variant="contained"
         target="_blank"
          component="a"
         href={'https://rminov.com/docs/idoomprint.html?code='+cardnumber+'&amount='+type+' '+amount}
        >
          Imprimer
        </Button>
      </div>

      
       
      <Dialog
        fullScreen
        open={openImage}
        onClose={handleCloseImage}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background:Colors.BACKGROUND_PRIMARY }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseImage}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, textAlign:'center' }} variant="h6" component="div">
              {cardnumber}
            </Typography>
            
          </Toolbar>
        </AppBar>
        <div className="card-body text-center mt-40">
          
            <img
              src={URL+cardImg}
              alt=""
              className="card_img_zoom"
            />
            </div>
        
      </Dialog>

      
    </>
  );
}
