import React, { useState, useEffect, useRef, useContext } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TWS } from "../../services/WebServices";
import SendLoading from "../flexy/SendLoading";
import Toolbar from '@mui/material/Toolbar';

import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import Chip from '@mui/material/Chip';
import {calculateTotalPaymentsToday} from '../../helpers'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function Payments({userid, usertoken}) {
    const [PaymentsList, setPaymentsList] = useState([]);
    const [loading, setloading] = useState(true);
    const [PaymentsListByAdmin, setPaymentsListByAdmin] = useState([]);


  useEffect(() => {

  getPayments()
getPaymentsByadmin()
    }, []);

function getPayments(){
    TWS.getPaymentsList(userid, usertoken)
      .then(res => {
        setPaymentsList(res);
        setloading(false)

      })
      .catch(() => {});
}

function getPaymentsByadmin(){
    TWS.getPaymentsListByAdmin(userid, usertoken)
      .then(res => {
        setPaymentsListByAdmin(res);
       
        setloading(false)
 
      })
      .catch(() => {});
}



 







  return (
    <>
    
    <Typography className="todaytotalp" variant="body1"  component="div">
          Total Aujourd'hui : {calculateTotalPaymentsToday(PaymentsList)} DA
    </Typography>

    
    
    <Toolbar
     className="tableTitle"
    >
    <Typography  className="table_p_title"  component="div">
          Paiements au Bureau
    </Typography>

    
    </Toolbar>
   {PaymentsListByAdmin.length > 0 ? (<TableContainer component={Paper} >
       
     
      <Table className="pro_tables" sx={{ minWidth: 650 }} aria-label="client table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell align="center">Région</TableCell>
            <TableCell align="center">Paiement&nbsp;(DA)</TableCell>
            <TableCell align="center">Validé</TableCell>
            <TableCell align="center">Date</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          { PaymentsListByAdmin.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              
            >
              <TableCell component="th" scope="row">
                {row.client.name}
              </TableCell>
          
            <TableCell align="center">{row.client.region}</TableCell>
              
              <TableCell align="center">{row.amount}</TableCell>
              <TableCell align="center"><Chip size="small" color="success" label={row.status ? 'OUI' : 'NON'} style={{backgroundColor: row.status ? 'green':'red'}} /></TableCell>
                <TableCell align="center"><Chip size="small" color="success" label={row.created_at} style={{backgroundColor: '#f1F1F1', color:'#000'}} /></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>):(
      <Typography className="text-center mb-4 mt-4" variant="body3"  component="div">
       Vous n'avez aucun paiement au bureau dans les 7 derniers jours.
      </Typography>
    )}

    <Toolbar
     className="tableTitle"
    >
    <Typography  className="table_p_title"  component="div">
          Paiements en point de vente 
        </Typography>

    
    </Toolbar>
    <TableContainer className="pro_tables" component={Paper} >
       
      <SendLoading loading={loading} />
      <Table sx={{ minWidth: 650 }} aria-label="client table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell align="center">Région</TableCell>
            <TableCell align="center">Paiement&nbsp;(DA)</TableCell>
            <TableCell align="center">Validé</TableCell>
            <TableCell align="center">Date</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {PaymentsList.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              
            >
              <TableCell component="th" scope="row">
                {row.client.name}
              </TableCell>
          
            <TableCell align="center">{row.client.region}</TableCell>
              
              <TableCell align="center">{row.amount}</TableCell>
              <TableCell align="center"><Chip size="small" color="success" label={row.status ? 'OUI' : 'NON'} style={{backgroundColor: row.status ? 'green':'red'}} /></TableCell>
                <TableCell align="center"><Chip size="small" color="success" label={row.created_at} style={{backgroundColor: '#f1F1F1', color:'#000'}} /></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   
    </>
  );
}