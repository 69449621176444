import React, { useState, useEffect, useRef, useContext } from "react";


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import TabList from "@mui/lab/TabList";
import Tab from '@mui/material/Tab';
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Box from '@mui/material/Box';
import Colors from "../../../constants/Colors";
import Typography from '@mui/material/Typography';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  


export default function Addbalance({openModal,handleClickClose, amount, selectAmount,handelAmount,clientname,selectedAmountIndex,addBalance,amountSim, selectAmountSim ,handelAmountSim,selectedAmountIndexSim,handelSimNumber,simNumber,addBalanceSim}) {
  
    let textInput = useRef(0);
    let textInput2 = useRef(0);
    let textInput3 = useRef(0);
    let textInput4 = useRef(0);

    const [selectedSimNumber, setselectedSimNumber] = useState(null);


 /**
 * TAB BALANCE
 */
  const [rechargeType, setrechargeType] = useState("1");

  const handleTabrechargeChange = (event, newValue) => {
    setrechargeType(newValue);
  };
  return (
    <Dialog
    open={openModal}
    TransitionComponent={Transition}
    keepMounted
    fullWidth={true}
    maxWidth={'sm'}
    onClose={handleClickClose}
    aria-describedby="alert-dialog-slide-description"
    sx={{
      padding:0,
      overflow:'hidden',
      
    }}
  >
   
    <DialogContent sx={{ padding:1 }}>
      <TabContext value={rechargeType}>
    <Box sx={{ borderBottom: 0, marginBottom:2, borderColor: 'divider', background:Colors.CONTENT_PRIMARY_REVERSED }}>
      <TabList
          onChange={handleTabrechargeChange}
          centered>
            <Tab label="REDMED APP" value="1" />
            <Tab label="PUCE AUXILIARE"  value="2" />
               
      </TabList>
    </Box>
      <TabPanel sx={{padding :0}} value={"1"} >
            <Typography sx={{ marginBottom:2, textTransform:'capitalize'}}variant="body1" gutterBottom align="center">
              {clientname + ' (شحن التطبيق)'}
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1  }} sx={{paddingInline:1, marginBottom:4}}>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 11 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined"  onClick={()=>{
              selectAmount(5000,11)
            }} >0.5 Million</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 1 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmount(10000,1)
            }} >1 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 2 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmount(20000,2)
            }} >2 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 3 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmount(30000,3)
            }} >3 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 4 ? "amount_btn amount_btn_selected" : "amount_btn"} variant="outlined" onClick={()=>{
              selectAmount(40000,4)
            }} >4 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 5 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmount(50000,5)
            }} >5 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 6 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined"onClick={()=>{
              selectAmount(60000,6)
            }} >6 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 7 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmount(70000,7)
            }} >7 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndex == 8 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmount(80000,8)
            }} >8 Millions</Button>
            </Grid>
          </Grid>
          
        <TextField
         inputRef={textInput}
          value={amount}
          label={"Quantité (DA)"}
          id="outlined-start-customer"
          className="input_add_balance"
          sx={{
            m: 1,
            width: "97%",
            backgroundColor: "#fff"
          }}
          type="number"
          
          onChange={event => {
            handelAmount(event.target.value);
          }}
        
        />
      </TabPanel>
      <TabPanel className="sim_r_panel" sx={{padding :0}} value={"2"} >
           <Typography sx={{ marginBottom:2, textTransform:'capitalize'}}variant="body1" gutterBottom align="center">
              {clientname + ' (شحن الشرائح)'}
            </Typography>
            
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1  }} sx={{paddingInline:1,marginTop:4, marginBottom:4}}>
            <Grid item xs={4}>
            <Button sx={{ height:45}}  className={selectedSimNumber == 77 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined"  onClick={()=>{
             handelSimNumber('0698683132')
             setselectedSimNumber(77)
            }} >
              <img className='operator_icon'src='/images/djezzy_logo.png'/>
              0698683132
              </Button>
            </Grid>
            <Grid item xs={4}>
            <Button sx={{ height:45}} className={selectedSimNumber == 66 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined"  onClick={()=>{
              handelSimNumber('0798683132')
              setselectedSimNumber(66)
            }} >
              <img className='mobilis_icon'src='/images/mobilis_logo.png'/>
              0698683132</Button>
            </Grid>
            <Grid item xs={4}>
            <Button sx={{ height:45}}  className={selectedSimNumber == 55 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined"  onClick={()=>{
              handelSimNumber('0598683132')
              setselectedSimNumber(55)
            }} >
              <img className='operator_icon'src='/images/ooredoo_logo.png'/>
              0698683132</Button>
            </Grid>
          </Grid>


        <Grid container rowSpacing={1} columnSpacing={{ xs: 1  }} sx={{paddingInline:1, marginBottom:1}}>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 11 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined"  onClick={()=>{
              selectAmountSim(5000,11)
            }} >0.5 Million</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 1 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmountSim(10000,1)
            }} >1 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 2 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmountSim(20000,2)
            }} >2 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 3 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmountSim(30000,3)
            }} >3 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 4 ? "amount_btn amount_btn_selected" : "amount_btn"} variant="outlined" onClick={()=>{
              selectAmountSim(40000,4)
            }} >4 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 5 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmountSim(50000,5)
            }} >5 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 6 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined"onClick={()=>{
              selectAmountSim(60000,6)
            }} >6 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 7 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmountSim(70000,7)
            }} >7 Millions</Button>
            </Grid>
            <Grid item xs={4}>
            <Button className={selectedAmountIndexSim == 8 ? "amount_btn amount_btn_selected" : "amount_btn"}  variant="outlined" onClick={()=>{
              selectAmountSim(80000,8)
            }} >8 Millions</Button>
            </Grid>
          </Grid>
        <TextField
         inputRef={textInput3}
          value={amountSim}
          label={"Quantité (DA)"}
          id="outlined-start-customer3"
          className="input_add_balance"
          sx={{
            m: 1,
            width: "97%",
            backgroundColor: "#fff",
            
          }}
          type="number"
          
          onChange={event => {
            handelAmountSim(event.target.value);
          }}
        
        />
        <TextField
         inputRef={textInput4}
          
          label={"PIN"}
          id="outlined-start-customer3"
          className="input_add_balance"
          sx={{
            m: 1,
            width: "97%",
            backgroundColor: "#fff"
          }}
          type="number"
          
          onChange={event => {
           
          }}
        
        />
      </TabPanel>
      </TabContext>

      
    </DialogContent>
    <DialogActions sx={{ padding:1 ,justifyContent:'center'}}>
      <Button  variant="contained" className="confirm_btn"  onClick={()=>{
        if (rechargeType == '1') {
          addBalance()
        } else {
          //addBalanceSim()
        }
        
        }}>Recharger</Button>
      <Button  variant="contained" onClick={handleClickClose}  className="annuler_btn">Fermer</Button>
    </DialogActions>
  </Dialog>
  );
}