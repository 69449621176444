/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect, useRef, useState } from "react";

import { TransactionsContext } from "../constants/hoop";

const TransactionsManager = props => {
  const [transactionussdcode, settransactionussdcode] = useState("");
  const [transactionbalance, settransactionbalance] = useState("");
  const [transactionphone, settransactionphone] = useState("");
  const [selectedchoise, setselectedchoise] = useState("");

  return (
    <TransactionsContext.Provider
      value={{
        transactionussdcode,
        settransactionussdcode,
        transactionbalance,
        settransactionbalance,
        transactionphone,
        settransactionphone,
        selectedchoise,
        setselectedchoise
      }}
    >
      {props.children}
    </TransactionsContext.Provider>
  );
};

export default TransactionsManager;
