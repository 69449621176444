import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Initialize FingerprintJS and get the visitor identifier when ready
const getDeviceFingerprint = async () => {
  // Initialize an agent at application startup.
  const fp = await FingerprintJS.load();

  // Get the visitor identifier when you need it.
  const result = await fp.get();

  // This is the visitor identifier:
  const visitorId = result.visitorId;

  return visitorId;
};

export default getDeviceFingerprint;
