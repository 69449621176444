import React, { useState, useEffect, useRef, useContext } from "react";
import Chart from "react-apexcharts";
import { TWS } from "../../services/WebServices";
import SendLoading from "../flexy/SendLoading";
import Counter from './proaccountcomp/Counter'

import {nFormatterk} from '../../helpers'
const Statistics = ({userid, usertoken}) => {

  const [list, setList] = useState([]);
 const [loading, setloading] = useState(true);
 const [xdata, setxdata] = useState([]);
  const [ydata, setydata] = useState([]);
  const [TotalPay, setTotalPay] = useState(0);
  const [NbrClient, setNbrClient] = useState(0);
  

  const series = [{
              name: "Transactions",
              data: ydata
            }]


  const options = {chart: {
                 height: 350,
                 type: 'bar',
                zoom: {
                  enabled: false
                }
              },
               plotOptions: {
              bar: {
                borderRadius: 10,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                  return val + " DA";
                },
                offsetY: -20,
                style: {
                  fontSize: '12px',
                  colors: ["#304758"]
                }
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: xdata,
                position: 'top',
                axisBorder: {
                  show: false
                },
                axisTicks: {
                  show: false
                },
                
                 fill: {
                  type: "gradient",
                  gradient: {
                    colorFrom: '#8343f5',
                    colorTo: '#c944ff',
                    shadeIntensity: 1,
                    opacityFrom: 0.5,
                    opacityTo: 1,
                    stops: [0, 90, 100]
                  }
                },
                tooltip: {
                  enabled: true,
                }
              },
              yaxis: {
                axisBorder: {
                  show: false
                },
                axisTicks: {
                  show: false,
                },
                labels: {
                  show: false,
                  formatter: function (val) {
                    return val + "%";
                  }
                }
              
              },
              title: {
                
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                  color: '#444'
                }
              }
            };


/**
 *  get stats
 */

useEffect(() => {
  getTotalstatistics()
  getstatsresp()
    }, []);

function getTotalstatistics(){
  
    TWS.getstatistics(userid, usertoken)
      .then(res => {
        setList(res.data);
        
        res.data.forEach(element => {
          xdata.push(element.sale_date)
          ydata.push(element.total_sale)
        });
        setloading(false)
 
      })
      .catch(() => {});
}

function getstatsresp(){
  
  TWS.getStatsResp(userid, usertoken)
  .then(res => {
  
    setNbrClient(res.clients);
    setTotalPay(res.payments)


  })
  .catch(() => {});
}

function getSumOfTransfertinWeek(){
  // Get current date
const now = new Date();
const dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 5 = Friday, 6 = Saturday

// Calculate the difference in days from the last Friday
const daysSinceFriday = (dayOfWeek + 2) % 7;

// Get the last Friday's date
const lastFriday = new Date(now);
lastFriday.setDate(now.getDate() - daysSinceFriday);


// Start counting from the day after the last Friday
const startDate = new Date(lastFriday);
startDate.setDate(lastFriday.getDate() + -1);

// Filter data to include only sales from last Friday onwards

const totalSales = list
    .filter(sale => new Date(sale.sale_date) >= startDate)
    .reduce((sum, sale) => sum + sale.total_sale, 0);

return totalSales
}

  return (
    <div>
      <SendLoading loading={loading} />
      {!loading &&(
        
       <>
       <Counter  nbrclients={NbrClient} totalpay={nFormatterk(TotalPay)} transferts={nFormatterk(getSumOfTransfertinWeek())} />
       <div id="chart">
          <Chart options={options} series={series} type="bar" height={350} />
        </div>
        </>
       )}
    </div>
  )
}

export default Statistics;