import React, { useState, useEffect, useRef, useContext } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import Badge from "@mui/material/Badge";


export default function SelectedPrepaidCard({
  cardnumber,
  amount,
  type,
  pts
}) {
  const { t } = useTranslation();
 

  return (
    <>
      <div className="card position-relative internet_card">
        <Badge color="secondary" badgeContent={pts + " Points"}></Badge>
        <div className="card-body text-center ">
          {type == "Mobilis" ? (
            <img
              src={
                amount == 500
                  ? "/images/carte500mobilis.png"
                  : amount == 1000
                  ? "/images/carte1000mobilis.png"
                  : "/images/carte1000mobilis.png"
              }
            
              className="card_img"
            />
          ) : type == "Djezzy" ? (
            <img
              src={
                amount == 100
                  ? "/images/carte100djezzy.png"
                  : amount == 500
                  ? "/images/carte500djezzy.png"
                  : "/images/carte1000djezzy.png"
              }
              alt=""
              className="card_img"
            />
          ) : (
            <img
              src={
                amount == 100
                  ? "/images/carte100ooredoo.png"
                  : amount == 500
                  ? "/images/carte500ooredoo.png"
                  : "/images/carte1000ooredoo.png"
              }
              alt=""
              className="card_img"
            />
          )}
        </div>
      </div>
      <div className="text-center mt-4 ">
        <Typography variant="h4" gutterBottom>
          {cardnumber}
        </Typography>
      </div>
      
      
     
      {/*<div className="text-center mt-4 ">
        <Button startIcon={<LocalPrintshopIcon />} variant="contained">
          Imprimer
        </Button>
      </div>*/}
    </>
  );
}
