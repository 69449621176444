import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLocation, useNavigate } from "react-router-dom";
export default function SuccessDialog(  {title,message,handelsucessdialog, status}) {
 
  const navigate = useNavigate();



  return (
    <div>
     
      <Dialog
      fullWidth={true}
      maxWidth={'sm'}
        open={status}
        onClose={handelsucessdialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       { title != null && (<DialogTitle id="alert-dialog-title">
        {title}
        </DialogTitle>)}
        <DialogContent>
        <div className="card-body text-center mb-4">
            <img
              src={
               "images/check.png" 
              }
             width='200'
            />
          </div>
          <div className='text-center'>
          <p>{message}</p>
          <Button 
          className='continue_btn'
          
          onClick={()=>{
            handelsucessdialog()
          }} autoFocus>
            OK
          </Button>
          </div>
        </DialogContent>
      
      </Dialog>
    </div>
  );
}