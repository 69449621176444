import React, { useState, useEffect, useRef, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { UserContext } from "../providers/UserProvider";
import { db } from "../services/Firestore";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { useTranslation } from "react-i18next";

export default function InputComponent({
  visible,
  handeloverlay,
  cardnumber,
  type,
  handelsmsresponse,
  deviceId
}) {
  const { t } = useTranslation();
  const [sentTo, setsentTo] = useState(null);

  const { user } = useContext(UserContext);

  /**
   * sen via sms
   */
  const sendSMS = async () => {
    const docref = doc(db, "smsrequests", deviceId);
    await setDoc(docref, {
      client_id: deviceId,
      user_id: user.id,
      status: "true",
      phone: sentTo,
      code: "Votre numéro de carte " + type + " est : " + cardnumber,
      type: "sms"
    }).then(() => {
      handelsmsresponse(true);
    });
  };
  return (
    <Dialog
      open={visible}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle> Entrez le numéro pour envoyer le code</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Téléphone"
          name="phone"
          type="number"
          autoComplete="phone"
          autoFocus
          onChange={event => {
            setsentTo(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className="annuler_btn"
          onClick={() => {
            handeloverlay(false);
          }}
        >
          Fermer
        </Button>
        <Button
          className="continue_btn"
          onClick={() => {
            handeloverlay(false);
            sendSMS();
          }}
        >
          Continuer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
