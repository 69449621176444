import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./i18n";

import "./style.css";
import "bootstrap/dist/css/bootstrap.css";
import AuthStack from "./providers/AuthStack";
const App = () => {
  return <AuthStack />;
};

export default App;
