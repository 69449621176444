import React, { useState, useEffect, useRef, useContext } from "react";

export default function TicketsRecharge({ data }) {
 
  return (
    <div className="col-md-12 mb-3 ">
      <div className="card rechargement_card transaction-item " >
      <div className="row ">
            <p className="text-end mb-0">{data.created_at}</p>
          </div>
        <div className={data.paid == 0 ? "card-body unpaid" : "card-body paid"}>
         
          <div className=" row d-flex align-items-center justify-content-between">
            <div className="text-center col">
              <div className="recharge_item text-center">
              <p className="recharge_item_subtitle">Mobilis</p>
              <p className="recharge_item_amount"> {data.mobilis} Paquets</p>
              </div>
            </div>

            <div className="text-center col">
              <div className="recharge_item text-center">
              <p className="recharge_item_subtitle">Djezzy</p>
              <p className="recharge_item_amount">{data.djezzy} Paquets</p>
              </div>
            </div>

            <div className="text-center col">
              <div className="recharge_item text-center">
              <p className="recharge_item_subtitle">Ooredoo</p>
              <p className="recharge_item_amount">{data.ooredoo} Paquets</p>
              </div>
            </div>

            <div className="text-center col">
              <div className="recharge_item text-center">
              <p className="recharge_item_subtitle">Retour</p>
              <p className="recharge_item_amount">{data.return} DA</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}
