import React, { useState, useEffect, useRef, useContext } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function Loading(props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="loading_img">
        <img src="/images/loading.gif" className="img-thumbnail" width="90" />
      </div>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        Chargement ...
      </Typography>
    </>
  );
}
