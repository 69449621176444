import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/UserProvider";
import CryptoJS from 'crypto-js';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Recharge = props => {
  const { t } = useTranslation();
  const initialValue = 0;
       const tawkMessengerRef = useRef(initialValue);

  const { user } = useContext(UserContext);
useEffect(() => {

}, []);
 /**
 *  DISCUSSION 
 */
    const onLoad = () => {
      
        //  setting name and email

        tawkMessengerRef.current.setAttributes({
          hash : HMAC( user.id,'5333b5ee8387fa1b2725e372443ff5f1ea8eb703'),    // required
          userId : user.id, 
            name : user.name,
            phone : user.phone,
          
        }, function(error) {
            // do something if error
        });

        tawkMessengerRef.current.maximize();
    };

async function HMAC(userid, apikey){

const hash = CryptoJS.HmacSHA256(userid, apikey)
  .toString(CryptoJS.enc.Hex)
 return hash;
}
  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />
      {/* main */}
      <div className="main-content">
       {/* <iframe src="https://tawk.to/chat/6496242694cf5d49dc5f8423/1h3l7qdle" width={'100%'} height={600} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"></iframe>*/}

         <TawkMessengerReact
                 onLoad={onLoad}
                propertyId="6496242694cf5d49dc5f8423"
                widgetId="1h3l7qdle"
                ref={tawkMessengerRef}/>
       </div>
         
    </div>
  );
};

export default Recharge;
