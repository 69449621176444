import * as React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function ShopProductCard({data,handleproductscart}) {
  const [openmsgcart, setOpenMsgcart] = React.useState(false);
  const [selecteditem, setSelectedItem] = React.useState(false);

  const addtocart = () => {
    setOpenMsgcart(true);
    setSelectedItem(data.name)
    handleproductscart(data)
    
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMsgcart(false);
  };

  return (
    <Card sx={{ maxWidth: 345 }} className='productcard'>
      <CardMedia
        sx={{ height: 200, backgroundSize:'contain' }}
        image={'https://mdash.rminov.com//'+data.images}
        title="green iguana"
      />
      
      <IconButton color="#fff" aria-label="add to shopping cart"  className='addtocart' onClick={addtocart}>
        <AddShoppingCartIcon />
      </IconButton>
      <CardContent className='productcardbody'>
        <p className='producttitle'>{data.name}</p>
        <div className="d-flex justify-content-center">
       {/**  <p className='productqty'>{data.qty} pièces</p>*/}
       {data.offers == null ? (
          <p className='productprice'>
            {data.price} DA
            </p>):
          (
            <p className='productprice'>
            {data.offers} DA
            <span className="slide-price-offer">{data.price} DA</span>
            </p>
                                    
         )}
        
        
        </div>
        
      </CardContent>

      <Snackbar open={openmsgcart} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {selecteditem + " ajouté au panier"} 
        </Alert>
      </Snackbar>
      
    </Card>
  );
}