import React, { useState, useEffect, useRef, useContext } from "react";

import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

import IconButton from '@mui/material/IconButton';

import FilledInput from '@mui/material/FilledInput';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import { TWS } from "../../services/WebServices";
import SuccessDialog from "../../components/SuccessDialog";





const Passwords = ({user}) => {
 
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [Password, setPassword] = useState(null);
  const [OldPassword, setOldPassword] = useState(null);
  const [showErrors, setshowErrors] = useState(false);
  const [sucessStatus, setSuccessStatus] = useState(false);
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowOldPassword = () => setShowOldPassword((showold) => !showold);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

function validation(){
 
  if(Password === null || OldPassword=== null || Password.length < 6 ) {
    setshowErrors(true)
    }else{
    
      postPass()
    }
}

  const postPass = async () => {
    
    try {
      
      await TWS.postUserPassword(Password,OldPassword,user.token).then(res => {
        handelsucessdialog()
        setshowErrors(false)
      });
  
    } catch (errors) {
      setshowErrors(true)
      //alert("une erreur est survenue veuillez réessayer ultérieurement");
    }
  
}
const handelsucessdialog = () => setSuccessStatus(!sucessStatus);


  return (

      <div className="pass-page">
      <SuccessDialog title={null} message='Opération effectuée avec succès' handelsucessdialog={handelsucessdialog} status={sucessStatus}/>
      {showErrors && ( <Stack sx={{ width: '95%' ,pl:2, pr:4 ,pb:4}} spacing={2}>
      <Alert severity="error">
        <AlertTitle>Erreur</AlertTitle>
        L'ancien mot de passe n'est pas valide <br></br>
        Le nouveau mot de passe doit être min 6 lettres 
     
      </Alert>
    </Stack>)}
      <div className="row px-4">
    <div className="settings">
      <div>
      <Typography variant="h6" gutterBottom>
       Changer Votre Mot de passe
      </Typography>
    
    <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
          <InputLabel htmlFor="filled-adornment-password">Ancien mot de passe</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={showOldPassword ? 'text' : 'password'}
            onChange={event => {
              setOldPassword(event.target.value);
            }}
            
           
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showOldPassword ? <Visibility /> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        </div>
        <div>
        <FormControl sx={{ m: 1, width: '35ch' }} variant="filled">
          <InputLabel htmlFor="filled-adornment-password-new">Nouveau mot de passe</InputLabel>
          <FilledInput
            id="filled-adornment-password-new"
            type={showPassword ? 'text' : 'password'}
            onChange={event => {
              setPassword(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }
          />
          
        </FormControl>
        </div>
       
        <Button
              onClick={() => {
                 
                validation()
                
              }}
              fullWidth
              variant="contained"
              sx={{ ml:1, mt: 2, mb: 2, height: 45, width: '40ch'  }}
              className="pass_btn"
              
            >
              Enregistrer
            </Button>
          </div>
        </div>
       
      </div>
    
  
  );
};

export default Passwords;
