import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
export default function Notifications({type,icon,msg}) {
  const { t } = useTranslation();
  return (
    <div className="container ">
      
      <div className={type + ' alert sold_status'} role="alert">
        {msg}
      </div>
      
    </div>
  );
}
