import React, { useState, useEffect, useRef, useContext } from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { formatnetworkCode } from "../../helpers";
import { TWS } from "../../services/WebServices";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";



export default function Inputs({
  handleNumberInputChange,
  networkOperatorCode,
  handleBlanceInputChange,
  enableinput,
  balance,
  selectedoffer,
  userId,
  phone,
  network,
  token,
handelCustomerName, 
name,handelNumberOffer
}) {
  const { t } = useTranslation();

  const [keyword, setkeyword] = useState("");
  const [results, setresults] = useState([]);
  const [isvisible, setisvisible] = useState(false);
  
 


  useEffect(() => {
    if (keyword.length > 0) {
      TWS.getSuggestionsWithName(userId, keyword, token)
        .then(res => {
         
          setresults(res);
        })
        .catch(() => {
          //TWS.onUnauthorized();
        });
        
    }
  }, [keyword]);
  const handleKeyDown = e => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  return (
    <div className="row justify-content-center">
      <div className="row justify-content-center">
        <div className="col-sm-5 col-md-10 position-relative">
          <TextField
            label={"Téléphone"}
            id="outlined-start-adornment"
            type="number"
            className="input_flexy"
            sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
            value={phone}
            InputProps={{
              startAdornment: (
                <InputAdornment className="input_flexy_start" position="start">
                  {formatnetworkCode(networkOperatorCode.current)}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment className="input_flexy_end" position="end">
                  {name}
                </InputAdornment>
              ),
              type: "search",
              autoComplete: "off"
            }}
            onChange={event => {
              setkeyword(
                formatnetworkCode(networkOperatorCode.current) +
                  event.target.value
              );

              handleNumberInputChange(event.target.value);
              if (event.target.value.length == 0) {
                setisvisible(false);
                setresults([]);
              } else if (event.target.value.length == 8) {
                setisvisible(false);
                setresults([]);
              } else {
                setisvisible(true);
              }
            }}
            onKeyDown={handleKeyDown}
          />
          {isvisible && results.length > 0 ? (
            <div className="phone_suggestions">
              <List>
                {results.map((item, index) => {
                  return (
                    <ListItem disablePadding key={index}>
                      <ListItemButton
                        onClick={() => {
                          handleNumberInputChange(item.phone.slice(2));
                          setisvisible(false);
                          setresults([]);
                          handelCustomerName(item.customer)
                          if(item.simcategory != null){
                            handelNumberOffer(item.simcategory)
                          }else{
                            handelNumberOffer(null)
                          }
                          
                        }}
                      >
                        <ListItemText
                          primary={item.phone}
                          secondary={item.customer}
                          className="phone_suggestions_text"
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          ) : null}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-5 col-md-10">
          {selectedoffer != null ? (
            <Typography variant="h4" gutterBottom className="selectedoffer">
              {selectedoffer.title}
            </Typography>
          ) : (
            <TextField
              label={"Montant"}
              id="outlined-start-amount"
              className="input_flexy"
              sx={{
                m: 1,
                width: "100%",
                backgroundColor: "#fff"
              }}
              type="number"
              value={balance}
              onChange={event => {
                handleBlanceInputChange(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    className="input_flexy_start"
                    position="start"
                  >
                    DA
                  </InputAdornment>
                )
              }}
            />
          )}
        </div>
      </div>
     {/*<div className="row justify-content-center">
        <FormGroup    sx={{ m: 1, width: "80%" }}>
          <FormControlLabel control={<Checkbox checked={isSpecialNumber}  onChange={handleChangecheckBox}  sx={{
          
          '&.Mui-checked': {
            color: pink[600],
          },
        }}/>} label="Numéro Spécial [O1]" />
        </FormGroup>
       
      </div>*/}
    </div>
  );
}
