import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from '@mui/material/Alert';

import { useTranslation } from "react-i18next";

export default function ErrorDialog({
  errordialog,
  handelerrordialog,
  message
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={errordialog}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle>{"Erreur"}</DialogTitle>
      <DialogContent> 
          <Alert severity="error">{message}</Alert>
      </DialogContent>
      <DialogActions>
        <Button
          className="annuler_btn"
          onClick={() => {
            handelerrordialog(false);
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
