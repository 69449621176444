// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMvzmvQjJKiTyMkfN2CAcsaJVKCnTRGDc",
  authDomain: "redmed-c128c.firebaseapp.com",
  databaseURL:
    "https://redmed-c128c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "redmed-c128c",
  storageBucket: "redmed-c128c.appspot.com",
  messagingSenderId: "699568596806",
  appId: "1:699568596806:web:27def2e4efa9b0bfeea747",
  measurementId: "G-2DVP3C988L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const auth = getAuth(app);
const messaging = getMessaging(app);
