import React, { useState, useEffect, useRef, useContext } from "react";

import { nFormatter } from "../../helpers";
import TextField from "@mui/material/TextField";
import SendButton from "../SendButton";
import SuccessDialog from "../SuccessDialog";
import {TWS} from '../../services/WebServices'
import { useLocation, useNavigate } from "react-router-dom";

import ErrorDialog from "../ErrorDialog";


export default function AddClient({userid, token}) {
  const [sucessStatus, setSuccessStatus] = useState(false);
  const [name, setname] = useState('');
  const [phone, setphone] = useState('');
  const [password, setpassword] = useState('');
  const [wilaya, setwilaya] = useState('');
  const [region, setregion] = useState('');
  const [showErrors, setshowErrors] = useState(false);
   const navigate = useNavigate();
   let textInput = useRef(0);


function validation(){
 
  if(password.length < 4 || name.length < 3  || wilaya.length < 3 || region.length < 3 || phone.length  < 10) {
    setshowErrors(true)
    }else{
    
      sendData()
    }
}

  const sendData = async () => {
    
    try {
      
      await TWS.addClientProAccount(token,userid,name,phone, password,wilaya, region).then(res => {
        handelsucessdialog()
        setshowErrors(false)
      });
  
    } catch (errors) {
      setshowErrors(true)
      //alert("une erreur est survenue veuillez réessayer ultérieurement");
    }
  
}
const handelsucessdialog = () => {
  restFields()
  setSuccessStatus(!sucessStatus)
};
const handelErrorDialog = () => setshowErrors(!showErrors);


function restFields() {
  setregion('')
  setphone('')
  setname('')
  setpassword('')
  setwilaya('')
  
}
  return (
<div className="row justify-content-center mt-2 add-client-form">
          <SuccessDialog title={null} message='Opération effectuée avec succès' handelsucessdialog={handelsucessdialog} status={sucessStatus} />
          <ErrorDialog
            errordialog={showErrors}
            handelerrordialog={handelErrorDialog}
            message={'Tous les champs sont obligatoires, il faut remplir les champs correctements'}
           
          />
          <div className="col-md-10">
          <TextField
              inputRef={textInput}
              value={name}
              label={"Nom et Prénom"}
              id="outlined-start-customer"
              className="input_add_customer"
              sx={{
                m: 1,
                width: "100%",
                backgroundColor: "#fff"
              }}
              type="text"
              
              onChange={event => {
                setname(event.target.value);
              }}
            
            />
             <TextField
              value={phone}
              inputRef={textInput}
             
              label={"Téléphone"}
              id="outlined-start-customer"
              className="input_add_customer"
              sx={{
                m: 1,
                width: "100%",
                backgroundColor: "#fff"
              }}
              type="number"
              
              onChange={event => {
                setphone(event.target.value);
              }}
        
            />
            <TextField
            value={password}
             inputRef={textInput}
             
              label={"Mot de passe"}
              id="outlined-start-customer"
              className="input_add_customer"
              sx={{
                m: 1,
                width: "100%",
                backgroundColor: "#fff"
              }}
              type="text"
              
              onChange={event => {
                setpassword(event.target.value);
              }}
            
            />
            </div>
            <div className="col-md-10">
            <div className="row justify-content-center">
              <div className="col-md-6">
            <TextField
            
             inputRef={textInput}
             value={wilaya}
              label={"Wilaya"}
              id="outlined-start-customer"
              className="input_add_customer"
              sx={{
                m: 1,
                width: "100%",
                backgroundColor: "#fff"
              }}
              type="text"
              
              onChange={event => {
                setwilaya(event.target.value);
              }}
            
            />
            </div>
            <div className="col-md-6">
             <TextField
             value={region}
              inputRef={textInput}
              label={"Région"}
           
              id="outlined-start-customer"
              className="input_add_customer"
              sx={{
                m: 1,
                width: "100%",
                backgroundColor: "#fff"
              }}
              type="text"
              
              onChange={event => {
                setregion(event.target.value);
              }}
            
            />
            </div>
            </div>
            </div>
        <SendButton onpress={()=>{
          validation()
         
        }} disabled={false} title={'Enregistrer'} icon={null}/>
      </div>
  )}