import React, { useState, useEffect, useRef, useContext } from "react";

import Dialog from "@mui/material/Dialog";

import { useTranslation } from "react-i18next";

export default function SendLoading({ loading }) {
  const { t } = useTranslation();

  return (
    <Dialog open={loading}>
      <img src="/images/loading.gif" width="80" height="80" />
    </Dialog>
  );
}
