import React, { useState, useEffect, useRef, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate
} from "react-router-dom";
import Navbar from "../components/Navbar";
import { useTranslation } from "react-i18next";
import { UserContext } from "../providers/UserProvider";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Inputs from "../components/flexy/Inputs";
import FloatingButton from "../components/flexy/FloatingButton";
import Colors from "../constants/Colors";
import Offers from "../components/flexy/Offers";
import { TransactionsContext } from "../constants/hoop";
import { formatnetworkCode } from "../helpers";
import { db } from "../services/Firestore";
import { TWS } from "../services/WebServices";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import BottomDrawerModal from "../components/flexy/BottomDrawerModal";
import ErrorDialog from "../components/ErrorDialog";
import Button from "@mui/material/Button";

const DjezzyFacture = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user, updateUserInfos } = useContext(UserContext);

  const {
    settransactionussdcode,
    settransactionbalance,
    settransactionphone,
    setselectedchoise,
    transactionbalance,
    transactionussdcode,
    selectedchoise,
    transactionphone
  } = useContext(TransactionsContext);
  const [phone, setPhone] = useState("");
  const [balance, setBalance] = useState("");
   const [CustomerName, setCustomerName] = useState("");
  const [ussdCodeRequest, setUssdCodeRequest] = useState(null);
  const { operator,isnewoperation } = useParams();
  const [entities, setEntities] = useState([]);
  const [is_sentRequest, setIs_sentRequest] = useState(0);
  let [ShowModelResponse, setShowModelResponse] = useState(false);
  const [buttonloading, setbuttonloading] = useState(false);
  const [buttondisabled, setbuttondisabled] = useState(true);
  const [ussdscodesflexy, setussdscodesFlexy] = useState([]);
  const [ussdscodesoffer, setussdscodesOffre] = useState([]);
  const [ussdscodesrequestOffer, setussdscodesrequestOffer] = useState([]);
  const [ussdforModalresponse, setussdforModalresponse] = useState(null);
  const [loadingussd, setloadingussd] = useState(false);
  const [enablebalanceInput, setsenablebalanceInput] = useState(true);
  const [selectedoffer, setSelectedOffer] = useState(null);
  const [errordialog, seterrordialog] = useState(false);
  const [errordialogMessage, seterrordialogMessage] = useState("");
  const [dataloading, setdataloading] = useState(false);
  const [conncetionStatus, setconncetionStatus] = useState(true);
  const [operationType, setoperationType] = useState("flexy"); // flexy or activation (for ready buttons)
  const [deviceId, setDeviceId] = useState(null);
  /**
   * sucess response handling
   */
  /*const simcardSucessResponse = useRef(
    operator == 1
      ? 't  transf r  avec succ s'
      : operator == 2
      ? 'Un message de confirmation sera envoy'
      : 'Ooredoo',
  );*/

  /*const simcardSucessResponse = [
    "t  transf r  avec succ s",
    "Un message de confirmation sera envoy",
    "TRANSFERE DE VOTRE COMPTE",
    "Dinar realisee",
    "OK num",
    "credit transmis"
  ];*/

  /**
   * check if new operation
   */

  useEffect(() => {
  
    if (isnewoperation == 'true') {
     setPhone('')
    }else{
     setPhone(transactionphone);
     setbuttondisabled(false)
    }
  }, []);
  const [simcardSucessResponse, setsimcardSucessResponse] = useState([]);
  useEffect(() => {
    TWS.getSucessMessages(user.token)
      .then(res => {
        setsimcardSucessResponse(res.mgs);
      })
      .catch(() => {
        setsimcardSucessResponse([
          "t  transf r  avec succ s",
          "Un message de confirmation sera envoy",
          "TRANSFERE DE VOTRE COMPTE",
          "Dinar realisee",
          "OK num",
          "credit transmis",
          "PAYEMENT DE FACTURE",
          "PAIEMENT DE FACTURE"
        ]);
      });
  }, []);

  function checksuccess(msg) {
    let check = false;
    for (let i = 0; i < simcardSucessResponse.length; i++) {
      check = msg.includes(simcardSucessResponse[i]);
      if (check) {
        break;
      }
    }

    return check;
  }

  /**
   * get device id
   */
  const asyncLocalStorage = {
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        setDeviceId(JSON.parse(deviceId));
      }
    });
  }, []);

  const handleNumberInputChange = number => {
    setPhone(number);
    settransactionphone(number); //context
    if (number.toString().length == 8) {
      setsenablebalanceInput(false);
      setbuttondisabled(false);
    } else {
      setbuttondisabled(true);
    }
  };

  const handleBlanceInputChange = value => {
    setBalance(value);
    settransactionbalance(value); //context
  };

  const handelErrorDialog = value => {
    seterrordialog(value);
  };

  /**
   * listen changes from firebase
   */
  useEffect(() => {
    if (is_sentRequest) {
      setdataloading(true);
      onSnapshot(doc(db, "responses", deviceId), doc => {
        // console.log("Current data: ", doc.data());
        if (doc.data().status == "true") {
          if (
            doc.data().response.length == 1 ||
            doc.data().response.length == 2
          ) {
            var transfertstatus = false;
            var msg = null;
            if (checksuccess(doc.data().response[0])) {
              transfertstatus = true;
            }
            msg = doc.data().response;
            updateStatusFinalChoise(transfertstatus, msg);
          } else {
            updateStatusFinalChoise(false, doc.data().response);
          }
        }
      });
    }
  }, [is_sentRequest]);

  const updateStatusFinalChoise = async (transfertstatus, msg) => {
    const docref = doc(db, "responses", deviceId);
    await updateDoc(docref, {
      status: "false"
    }).then(() => {
      navigate("/transactionstatus", {
        state: {
          status: transfertstatus,
          msg: msg,
          operator:operator,
          isDjezzyFacture: true,
        }
      });
      setbuttondisabled(false);
      setbuttonloading(false);
      //console.log('data updated!');
    });
  };

  /**
   * construct ussd code
   */
  function ussd_to_sent() {
    var ussdscode = "*761*num*amount*00000#";
    let tele = formatnetworkCode(networkOperatorCode.current) + phone;
    let code = ussdscode.replace("num", tele);
    code = code.replace("amount", balance);
    var ussd_to_sent = code;
    setUssdCodeRequest(code);
    return ussd_to_sent;
  }

  /**
   * send USSD code
   */

  const sendUssdCode = () => {
    if (
      user.activebalance[0].balance == 0 ||
      user.activebalance[0].balance < parseInt(balance)
    ) {
      seterrordialog(true);
      seterrordialogMessage("Solde insuffisant,merci de charger votre compte");
    } else {
      setbuttondisabled(true);
      setbuttonloading(true);

      if (phone.length != 8) {
        seterrordialog(true);
        seterrordialogMessage("Veuillez remplir le numéro de téléphone");
      } else {
        if (balance.length > 1) {
          if (parseInt(balance) >= 40) {
            var n_ussd = ussd_to_sent();
            sendUssdFlexyAndActivation(n_ussd);
          } else {
            seterrordialog(true);
            seterrordialogMessage(
              "Le montant doit être supérieur ou égal à 40DA"
            );
            setbuttondisabled(false);
            setbuttonloading(false);
          }
        }
      }
    }
  };

  /**
   * send flexy request
   */

  const sendUssdFlexyAndActivation = async n_ussd => {
    if (n_ussd != null) {
      const docref = doc(db, "requests", deviceId);
      await updateDoc(docref, {
        client_id: deviceId,
        user_id: user.id,
        status: "true",
        ussd: n_ussd,
        operator: parseInt(operator),
        type: "facturation",
        phone: phone,
        amount: balance
      }).then(() => {
        setIs_sentRequest(_old => _old + 1);
        setselectedchoise("facturation");
        //console.log('data sent!');
      });
    } else {
      seterrordialog(true);
      seterrordialogMessage(
        "Une erreur est survenue, merci de réessayer ulterieurement"
      );
    }
  };

  /**
   * Operator selection
   */
  const networkOperator = useRef(
    operator == 1 ? "Mobilis" : operator == 2 ? "Djezzy" : "Ooredoo"
  );
  const networkOperatorCode = useRef(operator == 1 ? 6 : operator == 2 ? 7 : 5);

 const handelCustomerName = value => {
    setCustomerName(value);
 }

 /**
 * handel Number Offer
 */
 const [NumberOffers, setNumberOffers] = useState(null);
const handelNumberOffer =(offers) =>{
setNumberOffers(null)
}

  return (
    <div className="wrapper">
      <Navbar active="home" props={props} data={user} />

      {/* main */}
      <div className="main-content pb-5 flexy-page">
        <div className="pt-100 container">
          <h1 className="pagetitle_intern text-center">
            {networkOperator.current} Facture
          </h1>
          <ErrorDialog
            errordialog={errordialog}
            handelerrordialog={handelErrorDialog}
            message={errordialogMessage}
          />
          {/* inputs */}
          
          <Inputs
            handleNumberInputChange={handleNumberInputChange}
            networkOperatorCode={networkOperatorCode}
            handleBlanceInputChange={handleBlanceInputChange}
            enableinput={enablebalanceInput}
            balance={balance}
            selectedoffer={selectedoffer}
            userId={user.id}
            phone={phone}
            network={networkOperator.current}
            token={user.token}
            handelCustomerName={handelCustomerName}
            name={CustomerName}
            handelNumberOffer={handelNumberOffer}
          />
        </div>
        <div className="text-center">
          <FloatingButton
            sendUssdCode={sendUssdCode}
            color={Colors.BACKGROUND_PRIMARY}
            loading={buttonloading}
            disabled={buttondisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default DjezzyFacture;
