import React, { useState, useEffect } from "react";

import CustomerRecharge from "./CustomerRecharge";
import ClientList from "./ClientList";
import AddClient from "./AddClient";
import { TWS } from "../../services/WebServices";
import SendLoading from "../flexy/SendLoading";
import Loading from "../Loading";
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Payments from "./Payments";
import Statistics from "./Statistics";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import Paper from '@mui/material/Paper';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddTaskIcon from '@mui/icons-material/AddTask';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

  

export default function ProAccount({ user }) {
  const [categoryIndex, setcategoryIndex] = useState(0);
  const [clientListrecharge, setClientListrecharge] = useState([]);
   const [total, setTotal] = useState(0);
    const [loadingdata, setloadingdata] = useState(true);
     


   const handelCategory =(index)=>{
      setcategoryIndex(index)
      if (index == 0) {
        getClientsRecharges()
      }
   }

 useEffect(() => {
 
  getClientsRecharges()

    }, []);

function getClientsRecharges(){
    TWS.rechargeListProAccount(user.id, user.token)
      .then(res => {
        setClientListrecharge(res.data);
        setloadingdata(false)
        var sum = 0;
        res.data.forEach(element => {
          sum += parseInt(element.unpaid);
        });
        setTotal(sum);
      })
      .catch(() => {});
}

  function searchClient(val){
    let query = ''
      if (val == '') {
        getClientsRecharges()
      } else {
        query = val.toLowerCase()

        const result = clientListrecharge.filter(elment => {
          let name_lowercase = elment.client.name.toLowerCase()
          var filtredcom = name_lowercase.startsWith(query)

          return filtredcom
        })

        setClientListrecharge(result);
      }
  }

 if (user.category != 1) return <Loading />;
  return (
    <div className="container text-center px-8">
              {/*<div className="row d-flex justify-content-center position-relative">
                <img src="images/redmedpay.png" className="paycard"/>
                {<span className="cardamount">{total+ ' DZD'} </span>}
            </div>*/}

            <div className="container container-md text-center menu_pro mt-4">
             <div className="btn-group categorybtnList" role="group" aria-label="Basic">
              <button type="button" className={categoryIndex == 0 ? "btn btn-category btn-cat-selected" :  "btn btn-category"} onClick={()=>{handelCategory(0)}}>Recharges</button>
              <button type="button" className={categoryIndex == 1 ? "btn btn-category btn-cat-selected" :  "btn btn-category"} onClick={()=>{handelCategory(1)}}>Clients</button>
              <button type="button" className={categoryIndex == 2 ? "btn btn-category btn-cat-selected" :  "btn btn-category"} onClick={()=>{handelCategory(2)}}>Paiements</button>
              <button type="button" className={categoryIndex == 3 ? "btn btn-category btn-cat-selected" :  "btn btn-category"} onClick={()=>{handelCategory(3)}}>Ajout</button>
              <button type="button" className={categoryIndex == 4 ? "btn btn-category btn-cat-selected" :  "btn btn-category"} onClick={()=>{handelCategory(4)}}>Statistiques</button>

            </div>
        </div>
     {categoryIndex == 0 ? (
     <div className="row">
        <SendLoading loading={loadingdata} />
        <div className="row  my-4 d-flex justify-content-md-end searchbyname rechargelist">
          <div className="col-sm-10 col-md-6">
            <TextField
                size="small"
                id="searchproaccount"
                label="Chercher par nom"
                type="text"
                sx={{width:'74%', background:'#fff'}}
                  InputLabelProps={{
                  shrink: true,
                                  
                  }}
                  onChange={event => {
                          searchClient(event.target.value);
                    }}
              />
              </div>
          </div>
         
         {clientListrecharge.map((row,index) => (
            <CustomerRecharge key={index} data={row}/>
         ))}
       
      </div>
      ) : categoryIndex == 1 ?(
         <>
         {/** <Typography variant="subtitle2" align='left' gutterBottom>
          {'Total à payer: '+ total + ' DZD'}
          </Typography>*/} 
          <ClientList userid={user.id} usertoken={user.token}/>
          </>
      ): categoryIndex == 2 ?(
         <>
         {/** <Typography variant="subtitle2" align='left' gutterBottom>
          {'Total à payer: '+ total + ' DZD'}
          </Typography>*/} 
          <Payments userid={user.id} usertoken={user.token}/>
          </>
      ) : categoryIndex == 4 ?(
         <>
        
          <Statistics userid={user.id} usertoken={user.token}/>
          </>
      )  :(
         <AddClient token={user.token} userid={user.id}/>
      )}


      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
        className="navigation_pro"
        sx={{
          paddingTop:1,
          height:65,
          "& .Mui-selected, .Mui-selected > svg": {
            color: "#8343f5"
          }
      }}
          showLabels
          value={categoryIndex}
          onChange={(event, newValue) => {
            
             handelCategory(newValue)
          }}
        >
          <BottomNavigationAction  label="Recharges" icon={<AddTaskIcon />} />
          <BottomNavigationAction  label="Clients" icon={<ListAltIcon />} />
          <BottomNavigationAction  label="Paiements" icon={<AccountBalanceWalletIcon />} />
          <BottomNavigationAction  label="Ajouter" icon={<AddReactionIcon />} />
          <BottomNavigationAction  label="Stats" icon={<QueryStatsIcon />} />
        </BottomNavigation>
      </Paper>
  </div>

  );
}



