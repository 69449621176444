import Login from "../pages/auth/Login";
import Home from "../pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function AuthNavigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} name="login" />
      </Routes>
    </BrowserRouter>
  );
}

export default AuthNavigation;
